<template>
  <div class="floting-container flex-align justify-between" :class="{'shadow': displayStep > 1}">
    <div class="flex-align">
      <button class="button" @click="$emit('prev')">{{ textPrev }}</button>
      <box-step class="margin-left-12" v-if="displayStep>1" :start-value="displayStep" :end-value="3"></box-step>
    </div>
    <div style="margin-right:-140px">나중에 하시겠습니까?
      <span class="text-underline font-primary unselect margin-left-4" @click="$emit('temp')">임시저장</span></div>
    <div class="flex-align">
      <box-step v-if="displayStep===1" :start-value="displayStep" :end-value="3"></box-step>
      <div v-if="displayStep>1" class="size-22 weight-600 margin-right-8">{{ totalPrice|currency }}</div>
      <button class="button is-primary margin-left-12" @click="$emit('next')">{{ textNext }}</button>
    </div>
  </div>
</template>

<script>
  import BoxStep from "./BoxStep";
  export default {
    name: "FloatingStep",
    components: {BoxStep},
    props: {
      step: {
        type: Number
      },
      service: {
        type: Object
      }
    },
    data() {
      return {
        text: {
          prev: '나가기',
          next: '다음'
        },
        displayStep: 1
      }
    },
    created() {
      this.setDisplayStep();
    },
    methods: {
      setDisplayStep() {
        let n = this.step;
        if(n === 1) {
          n = 1;
        }
        else if(n <= 3) {
          n = 2;
        }
        else {
          n = 3;
        }
        this.displayStep = n;
      },
    },
    computed: {
      textPrev() {
        return this.step === 1 ? '나가기' : '이전';
      },
      textNext() {
        let count = this.service.conversions.length + this.service.plugins.length;
        return this.step === 3 ? `다음(${count})` : '다음';
      },
      totalPrice() {
        let arr = this.service.conversions.concat(this.service.plugins);
        if(arr.length === 0) {
          return 0;
        }

        return arr.map(item => {
          return item.price.price;
        }).reduce((acc, cur) =>{
          return acc + cur
        });
      }
    },
    watch: {
      step() {
        this.setDisplayStep();
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .floting-container
    position fixed
    left 50%
    bottom 6%
    transform translateX(-50%)
    width 70%
    padding 16px
    z-index 10
  .shadow
    background-color white
    box-shadow 0 4px 7px #d4d4d4
</style>
